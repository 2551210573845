import React, { useEffect, useState } from "react";
import axios from "axios";

import "./LoginSecurity.css";

import ApexChart from "../../CommonComponents/ApexChart";
import LoginTable from "../../CommonComponents/LoginTable";
import LoginTableNew from "../../CommonComponents/LoginTableNew";
import LoadingPopup from "../../CommonComponents/LoadingPopup";

import CalendarBlue from "../../icons/Settings/Calendar blue.svg";
import CalendarRed from "../../icons/Settings/Calendar red.svg";

const LoginSecurity = () => {
  const [loading, setLoading] = useState(false);
  const [filist, setFis] = useState([]);

  const data = [
    {
      name: "John Doe",
      role: "Manager",
      loginTime: "9:00 AM",
      logoutTime: "5:00 PM",
      event: "Login",
      id: "12345",
    },
    {
      name: "Jane Smith",
      role: "Employee",
      loginTime: "9:15 AM",
      logoutTime: "5:15 PM",
      event: "Error",
      id: "67890",
    },
    {
      name: "Jane Smith",
      role: "Employee",
      loginTime: "9:15 AM",
      logoutTime: "5:15 PM",
      event: "Error",
      id: "67890",
    },
    {
      name: "Jane Smith",
      role: "Employee",
      loginTime: "9:15 AM",
      logoutTime: "5:15 PM",
      event: "Error",
      id: "67890",
    },
    {
      name: "Jane Smith",
      role: "Employee",
      loginTime: "9:15 AM",
      logoutTime: "5:15 PM",
      event: "Error",
      id: "67890",
    },
  ];

  useEffect(() => {
    fetchProducts();
  }, []);

  const [error, setError] = useState(null);

  const fetchProducts = async () => {
    setLoading(true);

    try {
      const response = await axios.get("https://digitalxapi.cncitsolutions.in/api/filist");
      setFis(response.data);
      setError(null); // Clear any previous error
    } catch (err) {
      if (err.response && err.response.status === 500) {
        setError("Internal Server Error: Something went wrong.");
      } else {
        // Handle other errors if needed
        setError("An error occurred while fetching data.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-security">
      <div className="top">
        <div className="left">
          <ApexChart />
        </div>
        <div className="right">
          <h4>Statistics</h4>
          <div className="stats-count">
            <div className="counts-1">
              <div className="Logins-count">
                <img src={CalendarBlue} alt="" />
                <div>
                  <label>2054</label>
                  <label>This Month Logins</label>
                </div>
              </div>
              <div className="Absence-count">
                <img src={CalendarRed} alt="" />
                <div>
                  <label>54</label>
                  <label>This Month Absents</label>
                </div>
              </div>
            </div>
            <div className="graph-1">
              <ApexChart />
            </div>
          </div>
        </div>
      </div>
      <h4>All logins</h4>
      <div className="bottom">
        {/* {loading ? <LoadingPopup show={true} /> : <LoginTable filist={filist} />} */}
        {loading ? (
          <LoadingPopup show={true} />
        ) : filist.length === 0 ? (
          <h3 className="h3-p-5">{error}</h3>
        ) : (
          <LoginTableNew data={data} />
        )}
      </div>
    </div>
  );
};

export default LoginSecurity;
