import React, { useState, useEffect } from "react";
import axios from "axios";

import Total from "../icons/transformation (3) 1.svg";
import Active from "../icons/interaction 1.svg";
import Inactive from "../icons/access-denied 1.svg";
import Planned from "../icons/internet-banking 1.svg";

import "./Moveables/SourceTree1.css";
import "./Builder.css";

import CompainStatus from "../CommonComponents/Status";
import SearchFilter from "../CommonComponents/SearchFilter";
import SearchResults from "../SearchResults";
import CampainTable from "./CampainTable";
import AddNewCampain from "./AddNewCampain";
import LoadingPopup from "../CommonComponents/LoadingPopup";

const CompainingMainCompo = () => {
  const [allData, setAllData] = useState([]);
  const [showAddNewCampain, setShowAddNewCampain] = useState(false);
  const [campainingData, setCampainingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");

  const [selectedUserId, setSelectedUserId] = useState("");

  const [selectedOption, setSelectedOption] = useState("");
  const [popupOptions, setPopupOptions] = useState("");

  const [saveBTN, setSaveBTN] = useState(false);
  const [saveCloseBTN, setSaveCloseBTN] = useState(false);

  const [treeData, setTreeData] = useState([
    {
      id: "1",
      text: "Newsletter",
      diamond: false,
      children: [],
    },
  ]);

  useEffect(() => {
    fetchProducts();
  }, []);

  const [error, setError] = useState(null);

  const fetchProducts = async () => {
    setLoading(true);

    try {
      const response = await axios.get("https://digitalxapi.cncitsolutions.in/api/campaining");
      setAllData(response.data);
      setCampainingData(
        response.data
          .filter((item) => item.isDisabled === 0)
          .filter((item) => item.isDeleted === 0)
          .filter((item) => item.yesNoValueField === "Yes")
      );
      setError(null); // Clear any previous error
    } catch (err) {
      if (err.response && err.response.status === 500) {
        setError("Internal Server Error: Something went wrong.");
      } else {
        // Handle other errors if needed
        setError("An error occurred while fetching data.");
      }
    } finally {
      setLoading(false);
    }
  };

  const statusData = [
    {
      icon: Total,
      title: "Total Campaigns",
      value: allData.length.toString(),
      upValue: "10%",
    },
    {
      icon: Active,
      title: "Active Campaigns",
      value: allData
        .filter((campaign) => campaign.isDisabled == 0)
        .length.toString(),
      upValue: "10%",
    },
    {
      icon: Inactive,
      title: "Inactive Campaigns",
      value: allData
        .filter((campaign) => campaign.isDisabled == 1)
        .length.toString(),
      upValue: "10%",
    },
    {
      icon: Planned,
      title: "Total FI",
      value: allData
        .filter((campaign) => campaign.campaigningStatus === "planned")
        .length.toString(),
    },
  ];

  const handleCloseAddNewCampain = (e) => {
    e.preventDefault();
    setShowAddNewCampain(false);

    setSelectedUserId("");
    setSaveBTN(false);
    setSaveCloseBTN(false);
    fetchProducts();
  };

  return (
    <>
      <div className="mainContainer">
        <div className="mainWrapper">
          {showAddNewCampain ? (
            <AddNewCampain
              treeData={treeData}
              setTreeData={setTreeData}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              selectedUserId={selectedUserId}
              setSelectedUserId={setSelectedUserId}
              showAddNewCampain={showAddNewCampain}
              setShowAddNewCampain={setShowAddNewCampain}
              handleCloseAddNewCampain={handleCloseAddNewCampain}
              popupOptions={popupOptions}
              setPopupOptions={setPopupOptions}
              fetchProducts={fetchProducts}
              saveBTN={saveBTN}
              setSaveBTN={setSaveBTN}
              saveCloseBTN={saveCloseBTN}
              setSaveCloseBTN={setSaveCloseBTN}
            />
          ) : (
            <>
              <div className="card-container">
                <CompainStatus
                  statusData={statusData}
                  campainingData={campainingData}
                  setCampainingData={setCampainingData}
                />
              </div>
              <div className="input-group searchFilterContainer">
                <SearchFilter
                  campaining={true}
                  filterPlaceholder="Search in Campaigning"
                  newButtonText="New"
                  setShowAddNewCampain={setShowAddNewCampain}
                  searchKeyword={searchKeyword}
                  setSearchKeyword={setSearchKeyword}
                />
              </div>
              <div className="searchResultsContainer">
                {loading ? (
                  <LoadingPopup show={true} />
                ) : campainingData.length === 0 ? (
                  <h3 className="h3-p-5">{error}</h3>
                ) : campainingData.some((item) => {
                    for (const key in item) {
                      const value = item[key];
                      if (
                        String(value)
                          .toLowerCase()
                          .includes(searchKeyword.toLowerCase())
                      ) {
                        return true;
                      }
                    }
                    return false;
                  }) ? (
                  <CampainTable
                    setShowAddNewCampain={setShowAddNewCampain}
                    setSelectedUserId={setSelectedUserId}
                    searchKeyword={searchKeyword}
                    campainingData={campainingData}
                    setCampainingData={setCampainingData}
                    fetchProducts={fetchProducts}
                  />
                ) : (
                  <SearchResults />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CompainingMainCompo;
