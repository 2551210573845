import React, { useState, useEffect } from "react";

import axios from "axios";

import ContnetStatus from "./ContnetStatus";
import SearchFilterAdd from "./SearchFilterAdd";
import ContentTable from "./ContentTable.js";
import ClientSection from "./FormComponents/ClientSelect";
import LoadingPopup from "../CommonComponents/LoadingPopup";
import SearchResults from "../CommonComponents/SearchResults";


import SMSIcon from "../icons/ConentManagement/image 111.svg";
import EmailIcon from "../icons/ConentManagement/image 111-1.svg";
import WhatsAppIcon from "../icons/ConentManagement/image 111-2.svg";
import IVRIcon from "../icons/ConentManagement/image 111-3.svg";
import BotIcon from "../icons/ConentManagement/image 111-4.svg";

const ContentMainComp = () => {
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");

  const [showClientSection, setShowClientSection] = useState(false);
  const [isMoreButtonVisible, setMoreButtonVisible] = useState(true);
  const [isCancelButtonVisible, setCancelButtonVisible] = useState(false);
  const [contentManagementData, setcontentManagementData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [activeForm, setActiveForm] = useState(null);

  useEffect(() => {
    fetchProducts();
  }, []);

  const [error, setError] = useState(null);

  const fetchProducts = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        "https://digitalxapi.cncitsolutions.in/api/ContentManagement"
      );
      setcontentManagementData(response.data);
      setError(null); // Clear any previous error
    } catch (err) {
      if (err.response && err.response.status === 500) {
        setError("Internal Server Error: Something went wrong.");
      } else {
        // Handle other errors if needed
        setError("An error occurred while fetching data.");
      }
    } finally {
      setLoading(false);
    }
  };

  const contentItems = [
    {
      text: "SMS",
      icon: SMSIcon,
      value: contentManagementData
        .filter((content_management) => content_management.Channel === "SMS")
        .length.toString(),
      bgColor: "bg-color-blue",
      textColor: "txt-col-blue",
    },
    {
      text: "Email",
      icon: EmailIcon,
      value: contentManagementData
        .filter((content_management) => content_management.Channel === "Email")
        .length.toString(),
      bgColor: "bg-color-yellow",
      textColor: "txt-col-yellow",
    },
    {
      text: "WhatsApp",
      icon: WhatsAppIcon,
      value: contentManagementData
        .filter(
          (content_management) => content_management.Channel === "WhatsApp"
        )
        .length.toString(),
      bgColor: "bg-color-red",
      textColor: "txt-col-red",
    },
    {
      text: "IVR",
      icon: IVRIcon,
      value: contentManagementData
        .filter((content_management) => content_management.Channel === "IVR")
        .length.toString(),
      bgColor: "bg-color-green",
      textColor: "txt-col-green",
    },
    {
      text: " Bot",
      icon: BotIcon,
      value: contentManagementData.length.toString(),
      bgColor: "bg-color-purple",
      textColor: "txt-col-purple",
    },
  ];

  const handleButtonClick = () => {
    setShowClientSection(true);
  };

  return (
    <div className="mainContainer">
      <div className="mainWrapper">
        {showClientSection ? (
          <ClientSection
            activeForm={activeForm}
            setActiveForm={setActiveForm}
            selectedUserId={selectedUserId}
            setSelectedUserId={setSelectedUserId}
            setShowClientSection={setShowClientSection}
          />
        ) : (
          <>
            <div className="card-container">
              <ContnetStatus items={contentItems} />
            </div>
            <div className="input-group searchFilterContainer">
              <SearchFilterAdd
                searchKeyword={searchKeyword}
                setSearchKeyword={setSearchKeyword}
                setSelectedChannel={setSelectedChannel}
                isCancelButtonVisible={isCancelButtonVisible}
                setCancelButtonVisible={setCancelButtonVisible}
                isMoreButtonVisible={isMoreButtonVisible}
                handleButtonClick={handleButtonClick}
              />
            </div>
            <div className="searchResultsContainer">
              {loading ? (
                <LoadingPopup show={true} />
              ) : contentManagementData.length === 0 ? (
                <h3 className="h3-p-5">{error}</h3>
              ) : contentManagementData.length === 0 ? (
                  <h3 className="h3-p-5">{error}</h3>
                ) : contentManagementData.some((item) => {
                  for (const key in item) {
                    const value = item[key];
                    if (
                      String(value)
                        .toLowerCase()
                        .includes(searchKeyword.toLowerCase())
                    ) {
                      return true;
                    }
                  }
                  return false;
                }) ? (
                  <ContentTable
                  contentManagementData={contentManagementData}
                  searchKeyword={searchKeyword}
                  selectedChannel={selectedChannel}
                  setCancelButtonVisible={setCancelButtonVisible}
                  isMoreButtonVisible={isMoreButtonVisible}
                  setMoreButtonVisible={setMoreButtonVisible}
                  setcontentManagementData={setcontentManagementData}
                  setShowClientSection={setShowClientSection}
                  setActiveForm={setActiveForm}
                  setSelectedUserId={setSelectedUserId}
                />
                ) : (
                  <SearchResults />
                )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ContentMainComp;
