import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";

import ReactPaginate from "react-paginate";

import "../ContentManagement/ContentTable.css"; //main css
import "../Campaign/CampainTable.css"; //remaining css

import SortIcon from "../icons/ConentManagement/Sort-1.svg";
import Ascending from "../icons/ConentManagement/ascending.svg";
import Descending from "../icons/ConentManagement/descending.svg";
import MoreOptions from "../icons/more-options.svg";

const TableSegmentationNew = ({
  segmentationData,
  setSegmentationData,
  searchKeyword,
  setShowAddNew,
  setSelectedUserId,
  selectedUserId

}) => {
  const [selectAll, setSelectAll] = useState(false);
    const [formData, setFormData] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [popupVisible, setPopupVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState({
    id: null,
    name: null,
    category: null,
    added_by: null,
    created_at: null,
    updated_at: null,
  });
  const filteredData = segmentationData.filter(
    (item) =>
      searchKeyword === "" ||
      item.name.toLowerCase().includes(searchKeyword.toLowerCase()) ||
      item.category.toLowerCase().includes(searchKeyword.toLowerCase()) ||
      item.created_at.toLowerCase().includes(searchKeyword.toLowerCase()) ||
      item.updated_at.toLowerCase().includes(searchKeyword.toLowerCase()) ||
      item.added_by.toString().includes(searchKeyword.toLowerCase()) ||
      item.id.toString().includes(searchKeyword.toLowerCase())
  );

  const pageCount = Math.ceil(filteredData.length / perPage);
  const offset = currentPage * perPage;
  const paginatedData = filteredData.slice(offset, offset + perPage);

  // const pageCount = Math.ceil(segmentationData.length / perPage);
  // const offset = currentPage * perPage;
  // const paginatedData = segmentationData.slice(offset, offset + perPage);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setSegmentationData((prevData) =>
      prevData.map((item) => ({
        ...item,
        selected: !selectAll,
      }))
    );
  };

  const handleSelect = (id) => {
    const newData = segmentationData.map((item) => {
      if (item.id === id) {
        return { ...item, selected: !item.selected };
      }
      return item;
    });
    setSegmentationData(newData);
    setSelectedRow(id); // Set the selected row
  };

  const handleSort = (field) => {
    let newSortOrder;
    setSortOrder((prevSortOrder) => {
      if (prevSortOrder[field] === "asc") {
        newSortOrder = "desc";
      } else if (prevSortOrder[field] === "desc") {
        newSortOrder = null;
      } else {
        newSortOrder = "asc";
      }

      return {
        ...prevSortOrder,
        [field]: newSortOrder,
      };
    });

    setSegmentationData((prevData) => {
      const newData = [...prevData];
      const currentSortOrder = sortOrder[field];

      newData.sort((a, b) => {
        if (currentSortOrder === "asc") {
          return a[field].localeCompare(b[field]);
        } else if (currentSortOrder === "desc") {
          return b[field].localeCompare(a[field]);
        } else {
          // original order
          return a.id - b.id;
        }
      });

      return newData;
    });
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const getSortButtonIcon = (field) => {
    const currentSortOrder = sortOrder[field];
    if (currentSortOrder === "asc") {
      return <img src={SortIcon} alt="Sort" />;
    } else if (currentSortOrder === "desc") {
      return <img src={Ascending} alt="Ascending" />;
    } else {
      return <img src={Descending} alt="Descending" />;
    }
  };

  const togglePopup = (id) => {
    setPopupVisible((prevPopupVisible) => !prevPopupVisible);
    setSelectedRow(id);
  };

const handleEdit = (selectedRow) => {
  setSelectedUserId(selectedRow.id); // Use the ID for identification
  setFormData(selectedRow); // Save the full data to pre-fill the form
  setShowAddNew(true); // Show the form for editing
};
const setValue = (field, value) => {
    console.log(`Setting field ${field} to value ${value}`);
  };
  // Track state changes using useEffect
  useEffect(() => {
    if (formData) {
      setValue("segmentDetailsName", formData.segmentDetailsName);
      setValue("segmentDetailsCategory", formData.segmentDetailsCategory);
      setValue("formdata", formData);
    }
  }, [formData]);


const handleClone = (id) => {
  const confirmDelete = window.confirm(
    `Are you sure you want to clone this item (id: ${id})?`
  );

  if (!confirmDelete) {
    return; // User canceled the clone action
  }

  // Fetch the data of the selected row
  axios
    .post(`https://digitalxapi.cncitsolutions.in/api/Segmentation/${id}/clone`)
    .then((response) => {
      const { status, data, message } = response.data; // Destructure the actual response structure
      if (status === true) {
        Swal.fire({
          icon: "success",
          text: "Row cloned and added successfully.",
        });
        window.location.reload(true);
      } else {
        Swal.fire({
          icon: "error",
          text: "Error cloning row 111.",
        });
      }

      // If additional actions with cloned data are needed
      if (data) {
        const clonedData = {
          ...data,
          // Modify fields here if necessary
        };

        // Example: Send a POST request to add the cloned row to the database
        axios
          .post("https://digitalxapi.cncitsolutions.in/api/Segmentation", clonedData)
          .then(({ data: responseData }) => {
            if (responseData.status === true) {
              window.location.reload(false);
            }
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              text: "Error cloning row 222.",
            });
            console.error("Error during clone saving:", error);
          });
      } else {
        Swal.fire({
          icon: "error",
          text: "Selected row not found.",
        });
      }
    })
    .catch((error) => {
      console.error("Error fetching row data:", error);
      Swal.fire({
        icon: "error",
        text: "Error cloning row 333.",
      });
    });
};

  const handleDisable = (id) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to disable this item (id: ${id})? This action can only be undone by an ADMIN.`
    );

    if (!confirmDelete) {
      return; // User canceled the delete action
    }

    // Send a DELETE request to the delete endpoint
    axios
      .post(`https://digitalxapi.cncitsolutions.in/api/Segmentation/${id}/disable`, {
        isDisabled: "1",
      })
      .then(({ data }) => {
        if (data.status === true) {
          Swal.fire({
            icon: "success",
            text: data.message,
          });
          window.location.reload(false);
        } else {
          Swal.fire({
            icon: "error",
            text: data.message,
          });
        }
      })
      .catch(({ data }) => {
        if (data.status === 422) {
        } else {
          Swal.fire({
            icon: "error",
            text: data.message,
          });
        }
      });
  };

  const handleDelete = (id) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete this item (id: ${id})? This action cannot be undone.`
    );

    if (!confirmDelete) {
      return; // User canceled the delete action
    }

    // Send a DELETE request to the delete endpoint
    axios
      .post(`https://digitalxapi.cncitsolutions.in/api/Segmentation/${id}/delete`, {
        isDeleted: "1",
      })
      .then(({ data }) => {
        if (data.status === true) {
          Swal.fire({
            icon: "success",
            text: data.message,
          });
          window.location.reload(false);
        } else {
          Swal.fire({
            icon: "error",
            text: data.message,
          });
        }
      })
      .catch(({ data }) => {
        if (data.status === 422) {
        } else {
          Swal.fire({
            icon: "error",
            text: data.message,
          });
        }
      });
  };

  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAll}
              />
            </th>
            <th>
              Name
              <button onClick={() => handleSort("name")}>
                {getSortButtonIcon("name")}
              </button>
            </th>
            <th>
              Category
              <button onClick={() => handleSort("category")}>
                {getSortButtonIcon("category")}
              </button>
            </th>
            <th>
              Date Created
              <button onClick={() => handleSort("created_at")}>
                {getSortButtonIcon("created_at")}
              </button>
            </th>
            <th>
              Date Modified
              <button onClick={() => handleSort("updated_at")}>
                {getSortButtonIcon("updated_at")}
              </button>
            </th>
            
            <th></th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map((item) => (
            <tr key={item.id}>
              <td>
                <input
                  type="checkbox"
                  checked={item.selected}
                  onChange={() => handleSelect(item.id)}
                />
              </td>
              <td>
                <div
                  className="name-toggle"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  {item.name}
                  {/* <button
                    className={`toggle-button-compain ${
                      isToggleOn ? "on" : ""
                    }`}
                    onClick={handleToggleClick}
                  >
                    Toggle
                  </button> */}
                </div>
              </td>
              <td>
                <div className="category-checkbox">
                  
                  {item.category}
                </div>
              </td>
              <td>{item.created_at}</td>
              <td>{item.updated_at}</td>
             
              <td>
                <div
                  className="more-icon"
                  style={{ position: "relative" }}
                  onClick={() => togglePopup(item.id)}
                >
                  <img src={MoreOptions} alt="MoreOptions" />
                  {selectedRow === item.id && popupVisible && (
                    <div className="popup-content-1">
                      <ul>
                        <li
                          onClick={() => handleEdit(item)}
                          className="blue"
                        >
                          Edit
                        </li>
                        <li
                          onClick={() => handleClone(selectedRow)}
                          className="blue"
                        >
                          Clone
                        </li>
                        <li
                          onClick={() => handleDisable(selectedRow)}
                          className="red"
                        >
                          Disable
                        </li>
                      
                      </ul>
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        <ReactPaginate
          previousLabel="&lt;"
          nextLabel="&gt;"
          breakLabel="..."
          breakClassName="break-me"
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName="pagination-container"
          activeClassName="active"
        />
        <div>
          <span>Output by: </span>
          <select
            value={perPage}
            onChange={(e) => setPerPage(parseInt(e.target.value, 10))}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
      </div>

      {/* <FilterAddContent isMoreButtonVisible={isMoreButtonVisible} /> */}
    </div>
  );
};

export default TableSegmentationNew;
