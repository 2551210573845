import React, { useState,useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";

import { EditorState, RichUtils, convertToRaw, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import DisplayFilterDetails from "./DisplayFilterDetails";

import SaveClose from "../icons/save-02.svg";
import CancelClose from "../icons/x-01.svg";

import "./AddNew.css";

import DetailsFilterComponent from "./DetailsFilterComponent";

const YesNoButtons = ({ label }) => {
  const [value, setValue] = useState("yes");

  const handleRadioChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div className="yesNoButtons">
    <h4>{label}</h4>
    <div>
    <label className={`radio-button ${value === "yes" ? "active" : ""}`}>
    <input
    style={{ display: "none" }}
    type="radio"
    value="yes"
    checked={value === "yes"}
    onChange={handleRadioChange}
    />
    Yes
    </label>
    <label className={`radio-button ${value === "no" ? "active" : ""}`}>
    <input
    style={{ display: "none" }}
    type="radio"
    value="no"
    checked={value === "no"}
    onChange={handleRadioChange}
    />
    No
    </label>
    </div>
    </div>
    );
  };

  const AddNew = ({ handleCloseAddNew, selectedUserId }) => {
    const url = window.$APIURL;
      useEffect(() => {
    if (selectedUserId) {
      axios
        .post(`${url}api/segmentation/${selectedUserId}`)
        .then(({ data }) => {
          if (data.status === true) {
            setallFormData(data.data);
            const segmentDetails = data.data;
            // Set form fields
            console.log(segmentDetails.segmentDetails.name)
            setSegmentTitle(segmentDetails.segmentDetails.name)
            setsegmentCategory(segmentDetails.segmentDetails.category)

            // Set editor state if description exists
            if (segmentDetails.description) {
              const contentState = convertFromRaw(
                JSON.parse(segmentDetails.segmentDetails.description)
              );
              setEditorState(EditorState.createWithContent(contentState));
            }
          } else {
            Swal.fire({
              icon: "error",
              text: data.message || "Error fetching details.",
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            text: "Failed to load data. Please try again.",
          });
          console.error(error);
        });
    }
  }, [selectedUserId]);



    const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [allFormData, setallFormData] = useState([]);

    const {
      register,
      handleSubmit,
      setValue,
      watch,
      formState: { errors, dirtyFields },
    } = useForm();

    const segmentDetailsName = watch("segmentDetailsName", "");
    const segmentDetailsCategory = watch("segmentDetailsCategory", "");

    const [segmentTitle, setSegmentTitle] = useState("");
    const [segmentCategory, setsegmentCategory] = useState("");

    const handleTitleChange = (event) => {
      setSegmentTitle(event.target.value);
    };

    const handleApply = (formdata, e) => {

      e.preventDefault();

      if (selectedUserId !== "") {
        alert("hellooo");
        alert(selectedUserId);
      } else {
        alert("Apply button clicked: " + JSON.stringify(formdata));
        console.log("Segment Form data:", formdata);
        var myform = document.getElementById("SegmentationDetailsFilterForm");
        var fd = new FormData(myform);

      // Convert editor state to JSON and append to form data

        axios
        .post(url + "api/segmentation/DetailsAndFilter", fd, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          console.log(data.status);
          console.log("in success",data)
          if (data.status == true) {
            Swal.fire({
              icon: "success",
              text: data.message,
            });
            localStorage.removeItem("segmentationDetailsForm");
            localStorage.removeItem("filterForm")
          } else {
            Swal.fire({
              icon: "error",
              text: data.message,
            });
          }
          handleCloseAddNew(); //commnent if you don't need to close form after submitting.
          // window.location.reload(false);
        })
       .catch((error) => {
    // Check if error response is defined
        console.log("Error",error)
    if (error.response && error.response.data) {
        // Now access the status from error.response.data
        const errorData = error.response.data;
        if (errorData.status === false) {
            Swal.fire({
                icon: "error",
                text: errorData.message,
            });
        } else {
            Swal.fire({
                icon: "error",
                text: errorData.message,
            });
        }
    } else {
        // Handle other errors, like network errors
        Swal.fire({
            icon: "error",
            text: "An unexpected error occurred. Please try again.",
        });
    }
          handleCloseAddNew(); //commnent if you don't need to close form after submitting.

});

      }
    };

    return (
      <>
      <form
      className="main-segment2"
      id="SegmentationDetailsFilterForm"
      onSubmit={handleSubmit(handleApply)}
      >
      <div className="top-search-buttons">
      <input
      className="newTitle"
      type="text"
      placeholder="New Segment"
      disabled
      value={segmentTitle}
      onChange={handleTitleChange}
      />
      <button
      className="functionBTN"
      type="submit"
      form="SegmentationDetailsFilterForm"
      >
      <img className="icon-all" src={SaveClose} alt="saveclose" /> Save &
      Close
      </button>
      <button onClick={handleCloseAddNew} className="functionBTN closeBTN">
      <img className="icon-all" src={CancelClose} alt="" /> Close
      </button>
      </div>
      <div className="segment2-body">
      <div className="segment2-body-left">
      <DetailsFilterComponent
      segmentDetailsName={segmentTitle}
      segmentDetailsCategory={segmentDetailsCategory}
      editorState={editorState}
      setEditorState={setEditorState}
      register={register}
      errors={errors}
      watch={watch}
      setValue={setValue}
      dirtyFields={dirtyFields}
      allFormData={allFormData}
      />
      </div>
      <div className="segment2-body-right">
      <div className="yes-no-wrapper">
      <YesNoButtons label="Publish" />
      </div>
      </div>
      </div>
      </form>
      </>
      );
    };

    export default AddNew;
