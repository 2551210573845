import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";

import "../ContentManagement/ContentTable.css"; //main css
import "./CampainTable.css"; //remaining css

import SortIcon from "../icons/ConentManagement/Sort-1.svg";
import Ascending from "../icons/ConentManagement/ascending.svg";
import Descending from "../icons/ConentManagement/descending.svg";
import MoreOptions from "../icons/more-options.svg";

const CampainTable = ({
  setSelectedUserId, //stores the selected row id
  campainingData,
  setCampainingData,
  searchKeyword,
  setShowAddNewCampain,

  fetchProducts,
}) => {
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [popupVisible, setPopupVisible] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState({
    id: null,
    Name: null,
    Category: null,
    CreatedBy: null,
    created_at: null,
    updated_at: null,
  });

  const key_word = searchKeyword.toString().toLowerCase();
  const filteredData = campainingData.filter(
    (item) =>
    key_word === "" ||
    item.id.toString().includes(key_word) ||
    item.Name.toLowerCase().includes(key_word) ||
    item.Category.toLowerCase().includes(key_word) ||
    item.created_at.toLowerCase().includes(key_word) ||
    item.updated_at.toLowerCase().includes(key_word) ||
    item.CreatedBy.toLowerCase().includes(key_word)
    );

  const pageCount = Math.ceil(filteredData.length / perPage);
  const offset = currentPage * perPage;
  const paginatedData = filteredData.slice(offset, offset + perPage);

  // const pageCount = Math.ceil(campainingData.length / perPage);
  // const offset = currentPage * perPage;
  // const paginatedData = campainingData.slice(offset, offset + perPage);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setCampainingData((prevData) =>
      prevData.map((item) => ({
        ...item,
        selected: !selectAll,
      }))
      );
  };

  const handleSelect = (id) => {
    const newData = campainingData.map((item) => {
      if (item.id === id) {
        return { ...item, selected: !item.selected };
      }
      return item;
    });
    setCampainingData(newData);
    setSelectedRow(id); // Set the selected row
  };

  let newSortOrder;

  const handleSort = (field) => {
    setSortOrder((prevSortOrder) => {
      if (prevSortOrder[field] === "asc") {
        newSortOrder = "desc";
      } else if (prevSortOrder[field] === "desc") {
        newSortOrder = null;
      } else {
        newSortOrder = "asc";
      }

      return {
        ...prevSortOrder,
        [field]: newSortOrder,
      };
    });

    setCampainingData((prevData) => {
      const newData = [...prevData];
      const currentSortOrder = sortOrder[field];

      newData.sort((a, b) => {
        if (currentSortOrder === "asc") {
          return a[field].localeCompare(b[field]);
        } else if (currentSortOrder === "desc") {
          return b[field].localeCompare(a[field]);
        } else {
          // original order
          return a.id - b.id;
        }
      });

      return newData;
    });
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const getSortButtonIcon = (field) => {
    const currentSortOrder = sortOrder[field];
    if (currentSortOrder === "asc") {
      return <img src={SortIcon} alt="Sort" />;
    } else if (currentSortOrder === "desc") {
      return <img src={Ascending} alt="Ascending" />;
    } else {
      return <img src={Descending} alt="Descending" />;
    }
  };

  const togglePopup = (id) => {
    setPopupVisible((prevPopupVisible) => !prevPopupVisible);
    setSelectedRow(id);
  };

  const handleEdit = (id) => {
    // alert(`Editing item with ID: ${id}`);
    setSelectedUserId(id);
    setShowAddNewCampain(true);
  };

  const handleClone = (id) => {
    const confirmDelete = window.confirm(
  `Are you sure you want to clone this item (id: ${id})?`
  );

    if (!confirmDelete) {
      return; // User canceled the delete action
    }

    // Fetch the data of the selected row
    axios
    .post(`https://digitalxapi.cncitsolutions.in/api/campaining/${id}/clone`)
    .then(({ data }) => {
      if (data) {
          // Create a new row with the same data
        const clonedData = {
          ...data,
            //id: null, // Set id to null or generate a new unique id if needed
            // You may also need to modify other fields if necessary
        };

          // Send a POST request to add the cloned row to the database
        axios
        .get("https://digitalxapi.cncitsolutions.in/api/campaining", clonedData)
        .then(({ data }) => {
          if (data.status == true) {
            Swal.fire({
              icon: "success",
              text: "Row cloned and added successfully.",
            });
            fetchProducts();
          } else {
            Swal.fire({
              icon: "error",
              text: "Error cloning row 1.",
            });
          }
        })
        .catch(({ data }) => {
          if (data.status == 422) {
          } else {
            Swal.fire({
              icon: "error",
              text: "Error cloning row 2.",
            });
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          text: "Selected row not found.",
        });
      }
    })
    .catch((error) => {
      console.error("Error fetching row data:", error);
    });
  };

  const handleDisable = (id) => {
    const confirmDelete = window.confirm(
  `Are you sure you want to disable this item (id: ${id})? This action can only be undone by an ADMIN.`
  );

    if (!confirmDelete) {
      return; // User canceled the delete action
    }

    // Send a DELETE request to the delete endpoint
    axios
    .post(`https://digitalxapi.cncitsolutions.in/api/campaining/${id}/disable`, {
      isDisabled: "1",
    })
    .then(({ data }) => {
      if (data.status == true) {
        Swal.fire({
          icon: "success",
          text: data.message,
        });
        fetchProducts();
      } else {
        Swal.fire({
          icon: "error",
          text: data.message,
        });
      }
    })
    .catch(({ data }) => {
      if (data.status === 422) {
      } else {
        Swal.fire({
          icon: "error",
          text: data.message,
        });
      }
    });
  };

  const handleDelete = (id) => {
    const confirmDelete = window.confirm(
  `Are you sure you want to delete this item (id: ${id})? This action cannot be undone.`
  );

    if (!confirmDelete) {
      return; // User canceled the delete action
    }

    // Send a DELETE request to the delete endpoint
    axios
    .post(`https://digitalxapi.cncitsolutions.in/api/campaining/${id}/delete`, {
      isDeleted: "1",
    })
    .then(({ data }) => {
      if (data.status == true) {
        Swal.fire({
          icon: "success",
          text: data.message,
        });
        fetchProducts();
      } else {
        Swal.fire({
          icon: "error",
          text: data.message,
        });
      }
    })
    .catch(({ data }) => {
      if (data.status === 422) {
      } else {
        Swal.fire({
          icon: "error",
          text: data.message,
        });
      }
    });
  };

  return (
    <div className="table-container">
    <table>
    <thead>
    <tr>
    <th>
    <input
    type="checkbox"
    checked={selectAll}
    onChange={handleSelectAll}
    />
    </th>
    <th>
    Campaign Name
    <button onClick={() => handleSort("Name")}>
    {getSortButtonIcon("Name")}
    </button>
    </th>
    <th>
    Category
    <button onClick={() => handleSort("Category")}>
    {getSortButtonIcon("Category")}
    </button>
    </th>
    <th>
    Date Created
    <button onClick={() => handleSort("created_at")}>
    {getSortButtonIcon("created_at")}
    </button>
    </th>
    <th>
    Date Modified
    <button onClick={() => handleSort("updated_at")}>
    {getSortButtonIcon("updated_at")}
    </button>
    </th>
    <th>
    Status
    <button onClick={() => handleSort("isDisabled")}>
    {getSortButtonIcon("isDisabled")}
    </button>
    </th>
    <th>
    Created By
    <button onClick={() => handleSort("CreatedBy")}>
    {getSortButtonIcon("CreatedBy")}
    </button>
    </th>
    <th></th>
    </tr>
    </thead>
    <tbody>
    {paginatedData
      .filter(
        (item) =>
        searchKeyword === "" ||
        item.Name.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        item.Category.toLowerCase().includes(
          searchKeyword.toLowerCase()
          ) ||
        item.created_at
        .toLowerCase()
        .includes(searchKeyword.toLowerCase()) ||
        item.updated_at
        .toLowerCase()
        .includes(searchKeyword.toLowerCase()) ||
        item.CreatedBy.toLowerCase().includes(
          searchKeyword.toLowerCase()
          )
        )
      .map((item) => (
        <tr key={item.id}>
        <td>
        <input
        type="checkbox"
        checked={item.selected}
        onChange={() => handleSelect(item.id)}
        />
        </td>
        <td>
        <div
        className="name-toggle"
        style={{ display: "flex", flexDirection: "row" }}
        >
        {item.Name}
        {/* <button
        className={`toggle-button-compain ${
          isToggleOn ? "on" : ""
        }`}
        onClick={handleToggleClick}
        >
        Toggle
                  </button> */}
        </div>
        </td>
        <td>
        <div className="category-checkbox">

        {item.Category}
        </div>
        </td>
        <td>{item.created_at}</td>
        <td>{item.updated_at}</td>
        <td>
        {item.isDisabled == 0 ? "Active" : "InActive"}
        </td>

        <td>{item.CreatedBy}</td>
        <td>
        <div
        className="more-icon"
        style={{ position: "relative" }}
        onClick={() => togglePopup(item.id)}
        >
        <img src={MoreOptions} alt="MoreOptions" />
        {selectedRow === item.id && popupVisible && (
          <div className="popup-content-1">
          <ul>
          <li
          onClick={() => handleEdit(selectedRow)}
          className="blue"
          >
          Edit
          </li>
          <li
          onClick={() => handleClone(selectedRow)}
          className="blue"
          >
          Clone
          </li>
          <li
          onClick={() => handleDisable(selectedRow)}
          className="red"
          >
          Disable
          </li>
          <li
          onClick={() => handleDelete(selectedRow)}
          className="red"
          >
          Delete
          </li>
          </ul>
          </div>
          )}
        </div>
        </td>
        </tr>
      ))}
      </tbody>
      </table>
      <div className="pagination">
      <ReactPaginate
      previousLabel="&lt;"
      nextLabel="&gt;"
      breakLabel="..."
      breakClassName="break-me"
      pageCount={pageCount}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={handlePageChange}
      containerClassName="pagination-container"
      activeClassName="active"
      />
      <div>
      <span>Output by: </span>
      <select
      value={perPage}
      onChange={(e) => setPerPage(parseInt(e.target.value, 10))}
      >
      <option value={10}>10</option>
      <option value={20}>20</option>
      <option value={50}>50</option>
      <option value={100}>100</option>
      </select>
      </div>
      </div>

      {/* <FilterAddContent isMoreButtonVisible={isMoreButtonVisible} /> */}
      </div>
      );
    };

    export default CampainTable;
