import React, { useState } from 'react';
import AccountSetting from './Sections/AccountSetting.js';
import Calendar from './Sections/CalendarComponent.js';
import Notifications from './Sections/Notifications.js';
import LoginSecurity from './Sections/LoginSecurity.js';

import './SettingsMainCompo.css';

const SettingsMainCompo = () => {
  const [activeTab, setActiveTab] = useState("Account Setting");
  
  const handleShowAccountSetting = () => {
    setActiveTab('Account Setting');
  };

  const handleShowCalendar = () => {
    setActiveTab('Calendar');
  };

  const handleShowNotifications = () => {
    setActiveTab('Notifications');
  };

  const handleShowLoginSecurity = () => {
    setActiveTab('Login & Security');
  };

  return (
    <div className="mainContainer settings">
      <div className="mainWrapper-settings">
      <div className="settings-btn-group">
        <button
          onClick={handleShowAccountSetting}
          className={activeTab === 'Account Setting' ? 'activeBorderBottom' : ''}
        >
          Account Setting
        </button>
        {/* <button
          onClick={handleShowCalendar}
          className={activeTab === 'Calendar' ? 'activeBorderBottom' : ''}
        >
          Calendar
        </button>
        <button
          onClick={handleShowNotifications}
          className={activeTab === 'Notifications' ? 'activeBorderBottom' : ''}
        >
          Notifications
        </button> */}
        <button
          onClick={handleShowLoginSecurity}
          className={activeTab === 'Login & Security' ? 'activeBorderBottom' : ''}
        >
          Login & Security
        </button>
      </div>
      {activeTab === 'Account Setting' && <AccountSetting />}
      {/* {activeTab === 'Calendar' && <Calendar />}
      {activeTab === 'Notifications' && <Notifications />} */}
      {activeTab === 'Login & Security' && <LoginSecurity />}
      </div>
    </div>
  );
};

export default SettingsMainCompo;
