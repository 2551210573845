import React, { useState, useRef } from 'react';
import './AccountSetting.css';
import AddGallary from '../../icons/Settings/gallery-add.svg';

const AccountSetting = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const [phoneBio, setPhoneBio] = useState(window.$USERDATA.bio);
  const [phoneExtension, setPhoneExtension] = useState(window.$USERDATA.phone_ext);
  const [phoneNumber, setPhoneNumber] = useState(window.$USERDATA.phone);
  const [email, setEmail] = useState(window.$USERDATA.email);
  const [fullName, setFullName] = useState(window.$USERDATA.name);

  const handleFileChange = ({ target }) => {
    if (target.files && target.files[0]) {
      setSelectedFile(URL.createObjectURL(target.files[0]));
    }
  };

  console.log("helloooooooooo",window.$USERDATA);

  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      <form className="settings-form" action="">
        <p>Your Profile Picture</p>
        <div className="upload-photo" onClick={handleDivClick}>
          {selectedFile ? (
            <img src={selectedFile} alt="" className="profile-img" />
          ) : (
            <>
              <img src={AddGallary} alt="" className="upload-img" />
              <p>Upload your photo</p>
            </>
          )}
          <input
            type="file"
            onChange={handleFileChange}
            ref={fileInputRef}
            style={{ display: 'none' }}
          />
        </div>
        <span className="full-width-line"></span>
        <div className="settings-form-fields">
          <label htmlFor="full-name">
            Full name
            <input
              type="text"
              id="full-name"
              name="fullName"
              value={fullName}
              placeholder="Please enter your full name"
              onChange={(e) => setFullName(e.target.value)}
            />
          </label>
          <label htmlFor="email">
            Email
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              placeholder="Please enter your email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
          <label htmlFor="phone-number">
            Phone number
            <div>
                <select
                value={phoneExtension}
                onChange={(e) => setPhoneExtension(e.target.value)}
              >
                <option value="+91">+91</option>
                <option value="+1">+1</option>
                <option value="+44">+44</option>
                {/* Add more options here */}
              </select>
                <input
                  type="tel"
                  id="phone-number"
                  name="phoneNumber"
                  value={phoneNumber}
                  placeholder="Please enter your phone number"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
            </div>
          </label>
          <label htmlFor="phone-bio">
            Bio
            <textarea
              id="phone-bio"
              name="phoneBio"
              value={phoneBio}
              placeholder="Write your Bio here e.g your hobbies, interests ETC"
              onChange={(e) => setPhoneBio(e.target.value)}
            />
          </label>
        </div>
        <div className="settings-btns">
          <button type='submit' className="upload-profile">Update Profile</button>
          <button type='reset' className="reset-profile">Reset</button>
        </div>
      </form>
    </>
  );
};

export default AccountSetting;
