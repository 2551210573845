import { Link, NavLink} from "react-router-dom";
import React, { useState } from "react";

import BarChartIcon from "./icons/Vector.svg";
import MainLogo from "./icons/xoto-logo-wo-bg.png";
import Campaign from "./icons/online-voting.svg";
import OnlineVoting from "./icons/campaign.svg";
import Banking1 from "./icons/banking1.svg";
import UserManagement from "./icons/management (1) 1.svg";
import Settings1 from "./icons/settings1.svg";
import GroupIcon1 from "./icons/GroupIcon1.svg";
import GroupIcon2 from "./icons/GroupIcon2.svg";
import Segmentation from "./icons/Segmentation.svg";
import axios from "axios";
import Swal from "sweetalert2";
import "./Sidebar.css";

import { useNavigate } from "react-router-dom";

const SidebarComp = () => {   
console.log("sadas",window.$USERDATA);

  return (
    <ul className="sidebarComp sidebar-menu">
    <li className="sidebar-li">
    <Link to="/">
    <img className="icons-all main-logo" src={MainLogo} alt="mainlogo" />
    </Link>
    </li>
    <li className="sidebar-li">
    <NavLink to="/Dashboard" className="active-sidebar" >
    <div className="icon">
    <img className="icons-all" src={BarChartIcon} alt="baricon" />
    </div>
    <div className="name">Dashboard </div>
    </NavLink>
    </li>

     <li className="sidebar-li">
    <NavLink to="/FiManagement" className="active-sidebar" >
    <div className="icon"><img className="icons-all" src={Banking1} alt="BarChartIcon" /></div>
    <div className="name">Data <br /> Management</div>
    </NavLink>
    </li>

    <li className="sidebar-li">
    <NavLink to="/Segmentation" className="active-sidebar" >
    <div className="icon">
    <img className="icons-all" src={Segmentation} alt="Segmentation" />
    </div>
    <div className="name">Segmentation</div>
    </NavLink>
    </li>
    <li className="sidebar-li">
    <NavLink to="/Campaign" className="active-sidebar" >
    <div className="icon"><img className="icons-all" src={Campaign} alt="Campaigning" /></div>
    <div className="name">Campaign  <br />Setup </div>
    </NavLink>
    </li>
    <li className="sidebar-li">
    <NavLink to="/ContentManagement" className="active-sidebar" >
    <div className="icon"><img className="icons-all" src={OnlineVoting} alt="OnlineVoting" /></div>
    <div className="name">Content <br /> Management</div>
    </NavLink>
    </li>
   

    {(window.$USERDATA.usertype == "ADMIN") ?     <li className="sidebar-li">
    <NavLink to="/UserManagement" className="active-sidebar" >
    <div className="icon"><img className="icons-all" src={UserManagement} alt="BarChartIcon" /></div>
    <div className="name">User  <br /> Management</div>
    </NavLink>
    </li> : <li></li>}


    <li className="gp-icons">
    <img className="icons-all" src={GroupIcon1} alt="" />
    <img className="icons-all" src={GroupIcon2} alt="" />
    </li>
    <li className="sidebar-li">
    <NavLink to="/Settings" className="active-sidebar" >
    <img className="icons-all" src={Settings1} alt="FiSettings" />
    <div className="name">Settings</div>
    </NavLink>
    </li>
    </ul>
    );
};

export default SidebarComp;
