import React from "react";

import MoreOptions from "../icons/more-options.svg";

const Table = ({ data }) => {
  return (
    <>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Role</th>
            <th>Log In Time</th>
            <th>Log Out Time</th>
            <th>Event</th>
            <th>ID</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td>
                <label>
                  {row.name
                    .split(" ")
                    .map((name) => name[0].toUpperCase())
                    .join("")}
                </label>
              </td>
              <td>{row.name}</td>
              <td>{row.role}</td>
              <td>{row.loginTime}</td>
              <td>{row.logoutTime}</td>
              <td>
                <label
                  style={{
                    padding: "0.5rem 2rem",
                    borderRadius: "7px",
                    backgroundColor:
                      row.event === "Login"
                        ? "#bbd4e7"
                        : row.event === "Error"
                        ? "#FFCBD4"
                        : "",
                    color:
                      row.event === "Login"
                        ? "#5C039B"
                        : row.event === "Error"
                        ? "#FF1C3B"
                        : "",
                  }}
                >
                  {row.event}
                </label>
              </td>
              <td>{row.id}</td>
              <td><button><img src={MoreOptions} alt="" /></button></td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default Table;
