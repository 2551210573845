import React, { useState } from "react";
import "./DashboardMainComp.css"; // related CSS
import "../Segmentaion/SegmentationMainComp.css"; // remaining common CSS
import { Bar, Pie,Line  } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  PointElement,
  LineElement
} from "chart.js";

ChartJS.register(ArcElement, BarElement, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend);

const MainComp = () => {
  // Sample data
  const platforms = ["SMS", "WhatsApp", "Email", "IVR", "Bot"];


  const campaigns = [
    { name: "Campaign 1", channel: "SMS", sent: 1200, opens: 300, engagementRate: "25%" },
    { name: "Campaign 2", channel: "IVR", sent: 800, opens: 200, engagementRate: "30%" },
    { name: "Campaign 3", channel: "WhatsApp", sent: 1500, opens: 600, engagementRate: "40%" },
    { name: "Campaign 4", channel: "Email", sent: 1000, opens: 400, engagementRate: "35%" },
    { name: "Campaign 5", channel: "SMS", sent: 2000, opens: 700, engagementRate: "35%" },
  ];


   // Mock Data
  const totalCampaigns = 50;
  const totalMessagesSent = 4500;
  const totalEngagements = 3200;
  const overallOpenRate = "35%";
  const [dateRange, setDateRange] = useState("Last 30 Days");

  const initialAnalyticsData = {
    SMS: 1200,
    WhatsApp: 800,
    Email: 1500,
    IVR: 600,
    Bot: 400,
  };

  const [analyticsData, setAnalyticsData] = useState(initialAnalyticsData);
  const [selectedDateRange, setSelectedDateRange] = useState("All Time");

  // Handle Date Range Change
  const handleDateChange = (event) => {
    const dateRange = event.target.value;
    setSelectedDateRange(dateRange);

    // Simulated filtering logic - replace with real API or data logic
    if (dateRange === "Last 7 Days") {
      setAnalyticsData({
        SMS: 800,
        WhatsApp: 500,
        Email: 1000,
        IVR: 400,
        Bot: 200,
      });
    } else if (dateRange === "Last 30 Days") {
      setAnalyticsData({
        SMS: 1000,
        WhatsApp: 700,
        Email: 1200,
        IVR: 500,
        Bot: 300,
      });
    } else {
      setAnalyticsData(initialAnalyticsData);
    }
  };

  // Prepare data for charts
  const barChartData = {
    labels: platforms,
    datasets: [
      {
        label: "Messages Sent",
        data: platforms.map((platform) => analyticsData[platform]),
        backgroundColor: [
          "rgba(54, 162, 235, 0.8)",
          "rgba(75, 192, 192, 0.8)",
          "rgba(255, 205, 86, 0.8)",
          "rgba(153, 102, 255, 0.8)",
          "rgba(255, 99, 132, 0.8)",
        ],
        borderColor: [
          "rgba(54, 162, 235, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(255, 205, 86, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 99, 132, 1)",
        ],
        borderWidth: 1,
        barThickness: 30,
      },
    ],
  };

  const pieChartData = {
    labels: platforms,
    datasets: [
      {
        data: platforms.map((platform) => analyticsData[platform]),
        backgroundColor: [
          "rgba(54, 162, 235, 0.8)",
          "rgba(75, 192, 192, 0.8)",
          "rgba(255, 205, 86, 0.8)",
          "rgba(153, 102, 255, 0.8)",
          "rgba(255, 99, 132, 0.8)",
        ],
        hoverOffset: 10,
      },
    ],
  };

   // Data for Line Graph
  const lineChartData = {
    labels: ["Day 1", "Day 2", "Day 3", "Day 4", "Day 5"],
    datasets: [
      {
        label: "Messages Sent",
        data: [1200, 1500, 1000, 2000, 1800],
        borderColor: "#2196f3",
        fill: false,
      },
      {
        label: "Opens",
        data: [300, 400, 350, 700, 600],
        borderColor: "#4caf50",
        fill: false,
      },
      {
        label: "Clicks",
        data: [100, 200, 150, 300, 250],
        borderColor: "#f44336",
        fill: false,
      },
    ],
  };

  return (
    <segment className="mainContainer">
      {/* Top Filter and Cards */}
    <div style={{ padding: "20px" }}>

      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
        <div style={cardStyle}>
          <h3  style={{ color: "#020202" }}>Total Campaigns</h3>
          <p style={cardValueStyle}>{totalCampaigns}</p>
        </div>
        <div style={cardStyle}>
          <h3  style={{ color: "#020202" }}>Messages Sent</h3>
          <p style={cardValueStyle}>{totalMessagesSent}</p>
        </div>
        <div style={cardStyle}>
          <h3  style={{ color: "#020202" }}>Total Engagement</h3>
          <p style={cardValueStyle}>{totalEngagements}</p>
        </div>
        <div style={cardStyle}>
          <h3  style={{ color: "#020202" }}>Overall Open Rate</h3>
          <p style={cardValueStyle}>{overallOpenRate}</p>
        </div>
      </div>



    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
          {/* Dropdown for date range */}
    <select value={selectedDateRange} onChange={handleDateChange} style={{ padding: "10px", fontSize: "16px" }}>
    <option value="All Time">All Time</option>
    <option value="Last 7 Days">Last 7 Days</option>
    <option value="Last 30 Days">Last 30 Days</option>
    </select>

          {/* Cards */}
    <div style={{ display: "flex", justifyContent: "space-around", width: "90%" }}>
    {platforms.map((platform, index) => (
      <div
      key={index}
      style={{
        background: "#f9f9f9",
        padding: "15px",
        borderRadius: "10px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        textAlign: "center",
        width: "18%",
      }}
      >
      <h3 style={{ color: "#5c039b" }}>{platform}</h3>
      <p style={{ fontSize: "20px", fontWeight: "bold" ,color: "#64ef0a"}}>{analyticsData[platform]}</p>
      <p style={{ color: "#03a4f4" }}>Messages Sent</p>
      </div>
      ))}
    </div>
    </div>


    {/* Bar Chart and Recent Campaigns Table */}
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
        {/* Bar Chart */}
        <div style={{ ...chartContainerStyle, width: "48%" }}>
          <h3>Channel Performance Comparison</h3>
          <Bar data={barChartData} />
        </div>

        {/* Recent Campaigns Table */}

         <div
  style={{
    width: "48%",
    height: "400px",
    background: "#f9f9f9",
    padding: "10px",
    paddingBottom: "80px",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    justifyContent: "center"
    }}
    >
    <h3 style={{ textAlign: "center" }}>Usage Distribution</h3>
    <Pie data={pieChartData} />
    </div>


       
      </div>



        {/* Charts */}
    <div style={{ display: "flex", justifyContent: "space-around", flexWrap: "wrap", marginTop: "20px" }}>


  {/* Pie Chart */}
  <div style={{ ...chartContainerStyle, width: "75%" }}>



          <h3>Recent Campaigns</h3>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th style={tableHeaderStyle}>Campaign Name</th>
                <th style={tableHeaderStyle}>Channel</th>
                <th style={tableHeaderStyle}>Messages Sent</th>
                <th style={tableHeaderStyle}>Opens</th>
                <th style={tableHeaderStyle}>Engagement Rate</th>
              </tr>
            </thead>
            <tbody>
              {campaigns.slice(0, 5).map((campaign, index) => (
                <tr key={index}>
                  <td style={tableCellStyle}>{campaign.name}</td>
                  <td style={tableCellStyle}>{campaign.channel}</td>
                  <td style={tableCellStyle}>{campaign.sent}</td>
                  <td style={tableCellStyle}>{campaign.opens}</td>
                  <td style={tableCellStyle}>{campaign.engagementRate}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
    </div>
    </div>
    </segment>
    );
};
// Styles
const cardStyle = {
  background: "#f9f9f9",
  padding: "20px",
  borderRadius: "10px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  textAlign: "center",
  flex: "1",
  margin: "0 10px",
};

const cardValueStyle = {
  fontSize: "24px",
  fontWeight: "bold",
  color: "5c039b"
};

const chartContainerStyle = {
  background: "#f9f9f9",
  padding: "20px",
  borderRadius: "10px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
};

const tableHeaderStyle = {
  borderBottom: "2px solid #ddd",
  textAlign: "left",
  padding: "10px",
};

const tableCellStyle = {
  borderBottom: "1px solid #ddd",
  padding: "10px",
};

export default MainComp;
