import React from "react";

import MoreOptions from "../icons/more-options.svg";
import UpArrow from "../icons/uparrow.svg";
import "./Status.css";

const Status = (props) => {
  const { statusData } = props;

  return (
    <>
      {statusData.map((item, index) => (
        <div className="card" key={index}>
          <img src={item.icon} className="card-img-top" alt="" />
          <div className="card-body">
            <h5 className="card-title">{item.title}</h5>
            <p className="card-text">{item.value}</p>
          </div>
          {item.upValue && (
            <div className="upValue">
              <div>
                <img src={UpArrow} alt="" />
                {item.upValue}
              </div>
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default Status;
