import React, { useState, useEffect } from "react";
import axios from "axios";

import { EditorState, RichUtils, convertToRaw, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import UndoIcon from "../icons/UndoIcon.svg";
import RedoIcon from "../icons/RedoIcon.svg";
import TrashIcon from "../icons/trash-02.svg";

import "./DetailsFilterComponent.css";

const DetailsFilterComponent = ({
  register,
  errors,
  watch,
  setValue,
  dirtyFields,
  editorState,
  setEditorState,
  segmentDetailsName,
  segmentDetailsCategory,
  allData
}) => {
  const [showDetailsForm, setShowDetailsForm] = useState(true);

  const handleShowDetailsForm = () => {
    setShowDetailsForm(true);
  };

  const handleShowFilterForm = () => {
    setShowDetailsForm(false);
  };
 useEffect(() => {
    setValue("segmentDetailsName", segmentDetailsName);
    setValue("segmentDetailsCategory", segmentDetailsCategory);
    console.log(segmentDetailsName)
  }, [segmentDetailsName, segmentDetailsCategory, setValue]);

  return (
    <>
      <div className="detail-filter-btn-group">
        <button
          type="button"
          onClick={handleShowDetailsForm}
          className={showDetailsForm ? "activeBorder" : " "}
        >
          Details
        </button>
        <button
          type="button"
          onClick={handleShowFilterForm}
          className={!showDetailsForm ? "activeBorder" : " "}
        >
          Filters
        </button>
      </div>
      <div className={showDetailsForm ? "visible" : "hidden"}>
        <DetailsForm
          register={register}
          errors={errors}
          watch={watch}
          setValue={setValue}
          dirtyFields={dirtyFields}
          editorState={editorState}
          setEditorState={setEditorState}
          segmentDetailsName={segmentDetailsName}
          segmentDetailsCategory={segmentDetailsCategory}
          allData
        />
      </div>
      <div className={!showDetailsForm ? "visible" : "hidden"}>
        <FilterForm />
      </div>
    </>
  );
};


const DetailsForm = ({
  register,
  setValue,
  watch,
  errors,

  editorState,
  setEditorState,

  segmentDetailsName,
  segmentDetailsCategory,
}) => {
  const [filist, setFis] = useState([]);

  // Fetch products (categories)
  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.get(
        "https://digitalxapi.cncitsolutions.in/api/FiUniqueCategory"
      );
      setFis(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handleDetailsNameChange = (event) => {
    const newName = event.target.value;
    alert(newName); // Temporary for debugging
    setValue("segmentDetailsName", newName); // Update form value
  };

  // Handle Undo and Redo for the editor
  const handleUndo = (e) => {
    e.preventDefault();
    setEditorState(EditorState.undo(editorState));
  };

  const handleRedo = (e) => {
    e.preventDefault();
    setEditorState(EditorState.redo(editorState));
  };

  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };

  const handleEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
    // If needed, store contentState to localStorage (convertToRaw).
  };
  const [FIData, setFIData] = useState([]);
  const [selectedOption2, setSelectedOption2] = useState("");
  const handleAudienceChange = (selectedOption) => {
    selectedOption == null
      ? setSelectedOption2("")
      : setSelectedOption2(selectedOption.value);
  };


  useEffect(() => {
    fetch("https://digitalxapi.cncitsolutions.in/api/filist")
      .then((response) => response.json())
      .then((data) => {
        const formattedOptions = data.map((segment) => ({
          value: segment.id,
          label: segment.finame,
        }));
        setFIData(formattedOptions);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <div className="detailsForm">
      {/* Name Input Field */}
      <div style={{ position: "relative" }}>
        <label htmlFor="segmentDetailsName">Segment Name:</label>
        <input
          placeholder="Type the name"
          type="text"
          id="segmentDetailsName"
          name="segmentDetailsName"
          onChange={handleDetailsNameChange}
          {...register("segmentDetailsName", { required: true })}
        />
        {errors.segmentDetailsName && (
          <span className="error-message">This field is required</span>
        )}
      </div>

      <div style={{ position: "relative", marginTop: "20px" }}>
        <label htmlFor="Category">Select a Client:</label>
        <select
          name="AudienceCampaigning"
          id="AudienceCampaigning"
          value={selectedOption2}
          onChange={handleAudienceChange}
        >
          <option value="" selected disabled>
            Choose one...
          </option>
          {FIData.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      {/* Category Select Field */}
      <div style={{ position: "relative", marginTop: "20px" }}>
        <label htmlFor="segmentDetailsCategory">Category:</label>
        <select
          id="segmentDetailsCategory"
          name="segmentDetailsCategory"
          value={segmentDetailsCategory}
          {...register("segmentDetailsCategory", { required: true })}
          onChange={(e) => {
            const selectedValue = e.target.value;
            setValue("segmentDetailsCategory", selectedValue); // Sync with form state
            localStorage.setItem("selectedCategory", selectedValue); // Store selected category in localStorage
          }}
        >
          <option value="">Select Category</option>
          <option value="Mortagages">Mortagages</option>
                  <option value="Real Estate">Real Estate</option>
                  <option value="Brokers">Brokers</option>
                  <option value="Builders">Builders</option>
                  <option value="Sales">Sales</option>
                  <option value="Newsletter">Newsletter</option>
                  <option value="Third Party Agency">Third Party Agency</option>
                  <option value="Property Loans">Property Loans</option>
                  <option value="Secured Loans">Secured Loans</option>
                  <option value="Car Loans">Car Loans</option>
                  <option value="BANKS">BANKS</option>
                  <option value="2 Wheeler Loans">2 Wheeler Loans</option>
                  <option value="Business Loans">Business Loans</option>
                  <option value="Wedding Loans">Wedding Loans</option>
                  <option value="Other">Other</option>

        </select>
        {errors.segmentDetailsCategory && (
          <span className="error-message">This field is required</span>
        )}
      </div>

      {/* Description Textarea */}
      <div style={{ position: "relative", marginTop: "20px" }}>
        <label htmlFor="segmentDescriptionEditor">Description:</label>
        <textarea
          id="segmentDescriptionEditor"
          name="segmentDescriptionEditor"
          cols="60"
          rows="5"
        />
      </div>
    </div>
  );
};


const FilterForm = () => {
  const [selectedOption, setSelectedOption] = useState(" ");
  const [tags, setTags] = useState([]);
  const [nextTagId, setNextTagId] = useState(1);
  const [inclusionOptions, setInclusionOptions] = useState({});

  const handleOptionChange = (event) => {
    const selected = event.target.value;
    if (selected !== "" && !tags.some((tag) => tag.option === selected)) {
      setSelectedOption(selected);
      setTags((prevTags) => [
        ...prevTags,
        { id: nextTagId, option: selected, tag: "", andOr: "AND" },
      ]);
      setInclusionOptions((prevOptions) => ({
        ...prevOptions,
        [nextTagId]: "Including", // Default value
      }));
      setNextTagId((prevId) => prevId + 1);
    }
  };

  const handleTagChange = (tagId, event) => {
    const newTags = tags.map((tag) =>
      tag.id === tagId ? { ...tag, tag: event.target.value } : tag
    );
    setTags(newTags);
  };

  const handleAndOrChange = (tagId, andOrValue) => {
    const newTags = tags.map((tag) =>
      tag.id === tagId ? { ...tag, andOr: andOrValue } : tag
    );
    setTags(newTags);
  };

  const handleRemoveTag = (tagId) => {
    setTags((prevTags) => prevTags.filter((tag) => tag.id !== tagId));
    setSelectedOption(" ");
  };

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("filterForm"));
    if (storedData) {
      setSelectedOption(storedData.selectedOption);
      setTags(storedData.tags);
      setNextTagId(storedData.nextTagId);
      setInclusionOptions(storedData.inclusionOptions);
    }
  }, []);

  useEffect(() => {
    const dataToStore = JSON.stringify({
      selectedOption,
      tags,
      nextTagId,
      inclusionOptions,
    });
    localStorage.setItem("filterForm", dataToStore);
  }, [selectedOption, tags, nextTagId, inclusionOptions]);

  return (
    <div className="filter-main segment-body1">
      <label className="filter-wrapper">
       
        <select  name="filterChooseOptions1" id="filterChooseOptions" value={selectedOption} onChange={handleOptionChange}>
        <option value="">Choose one..</option>
        <option value="Name">Name</option>
        <option value="pan">PAN</option>
        <option value="DOB">DOB</option>
        <option value="ContactNumber">ContactNumber</option>
        <option value="Email">Email</option>
        <option value="Address">Address</option>
        <option value="City">City</option>
        <option value="State">State</option>
        <option value="Pincode">Pincode</option>
        <option value="AlternateMobile1">AlternateMobile1</option>
        <option value="AlternateMobile2">AlternateMobile2</option>
        <option value="Refrence">Refrence</option>
        <option value="Agency">Agency</option>
        <option value="Builder">Builder</option>
        <option value="ChannelPartner">Channel Partner</option>
        <option value="BusinessCategory">BusinessCategory </option>
       
        </select>
      </label>
      {tags.map((tag) => (
        <div key={tag.id} style={{ width: "100%" }}>
        
          <div className="and-or-wrapper">
            <label
              className={`and-or-label ${tag.andOr === "AND" ? "active" : ""}`}
            >
              <input
                style={{ display: "none" }}
                type="radio"
                value="AND"
                id={`AND${tag.id}`}
                name={`AND${tag.id}`}
                checked={tag.andOr === "AND"}
                onChange={() => handleAndOrChange(tag.id, "AND")}
              />
              AND
            </label>
            <label
              className={`and-or-label ${tag.andOr === "OR" ? "active" : ""}`}
            >
              <input
                style={{ display: "none" }}
                type="radio"
                value="OR"
                id={`OR${tag.id}`}
                name={`OR${tag.id}`}
                checked={tag.andOr === "OR"}
                onChange={() => handleAndOrChange(tag.id, "OR")}
              />
              OR
            </label>
          </div>
          <div className="tags-wrapper">
            <label htmlFor={tag.id} className="options-label">
              {tag.option}
              
            </label>
             <input
                type="hidden"
                value={tag.option}
                name={`column[]`}
              />
               <input
                type="hidden"
                value={tag.id}
                name={`tag_id[]`}
              />
            <select
              value={inclusionOptions[tag.id] || "Including"}
              id={`inclusion-options-${tag.id}`}
              name={`inclusionoptions[]`}
              onChange={(event) =>
                setInclusionOptions((prevOptions) => ({
                  ...prevOptions,
                  [tag.id]: event.target.value,
                }))
              }
            >
              <option value="inc">Including</option>
              <option value="exc">Excluding</option>
            </select>
            <input
              type="text"
              id={`tag-names-${tag.id}`}
              name={`tagnames[]`}
              className="tag-names"
              value={tag.tag}
              onChange={(event) => handleTagChange(tag.id, event)}
            />
            <button onClick={() => handleRemoveTag(tag.id)}>
              <img src={TrashIcon} alt="deleteIcon" />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DetailsFilterComponent;
