import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import CloseIcon from "../icons/X-02.svg";
import UploadIcon from "../icons/UploadIcon.svg";
import DeleteIcon from "../icons/trash-02.svg";

import "./FiPopup.css";

const FiPopup = ({ onClose, Title1, rowData, fetchProducts }) => {
  const url = window.$APIURL;
  const [selectedFile, setSelectedFile] = useState(null);
  const [filist, setFis] = useState([]);

  const {
    register,
    handleSubmit,
    setValue, // Allows setting form values programmatically
    formState: { errors },
  } = useForm();

  useEffect(() => {
    fetchData();
    if (rowData) {
      // Pre-fill form fields with rowData
      setValue("finame", rowData.finame);
      setValue("id", rowData.id);
      setValue("location", rowData.location);
      setValue("category", rowData.category);
      setValue("description", rowData.description || "");
      setValue("poc_name", rowData.poc_name || "");
      setValue("poc_email", rowData.poc_email || "");
      setValue("poc_phone", rowData.poc_phone || "");
      setValue("poc_phone", rowData.poc_phone || "");
      setValue("poc_phone_ext", rowData.poc_phone_ext || "");
      setSelectedFile(rowData.filogo ? { name: rowData.filogo } : null);
    }
  }, [rowData, setValue]);

  const fetchData = async () => {
    await axios
      .get("https://digitalxapi.cncitsolutions.in/api/FiUniqueCategory")
      .then(({ data }) => {
        setFis(data);
      });
  };

  const onSubmit = (formdata) => {
    var fd = new FormData();
    Object.keys(formdata).forEach((key) => {
      fd.append(key, formdata[key]);
    });
    if (selectedFile) {
      fd.append("profile", selectedFile);
    }

    axios
      .post(url + "api/fi/add", fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => {
        if (data.status) {
          Swal.fire({
            icon: "success",
            text: data.message,
          });
          fetchProducts && fetchProducts();
        } else {
          Swal.fire({
            icon: "error",
            text: data.message,
          });
        }
          fetchProducts && fetchProducts();
        
        onClose();
      })
      .catch(({ data }) => {
        Swal.fire({
          text: data.message,
          icon: "error",
        });
      });
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <div className="popup-header">
          <img onClick={onClose} className="close-icon" src={CloseIcon} alt="x" />
          <h2>Add Client</h2>
        </div>
        <form id="addfiForm" onSubmit={handleSubmit(onSubmit)}>
          <div className="popup-body">
            <div className="left-body">
              <div className="body-element">
                <label>Name</label>
                <input
                  type="text"
                  {...register("finame", { required: "This Field is empty" })}
                />
                {errors.finame && <span className="error">{errors.finame.message}</span>}
              </div>
              <div className="body-element">
                <label>Logo</label>
                <div className="addClient-field-1">
                  <input
                    type="file"
                    name="filogo"
                    id="filogo"
                    accept=".png, .jpg, .jpeg"
                    onChange={(e) => setSelectedFile(e.target.files[0])}
                    style={{ display: "none" }}
                  />
                  <input
                    type="text"
                    placeholder="File name shown here"
                    value={selectedFile ? selectedFile.name : ""}
                    readOnly
                  />
                  <label htmlFor="filogo" className="file-upload-label">
                    <img src={UploadIcon} alt="Upload" />
                  </label>
                  {selectedFile && (
                    <img
                      src={DeleteIcon}
                      alt="Delete"
                      onClick={() => setSelectedFile(null)}
                    />
                  )}
                </div>
              </div>
               <div className="body-element">
                <label>Category</label>
                <select
                  id="fiDetailsCategory"
                  name="fiDetailsCategory"
                  {...register("category", { required: true })}
                >
                  {/* name="segmentDetailsCategory" {...register("fi_sel")}> */}
                  <option value="Mortagages">Mortagages</option>
                  <option value="Real Estate">Real Estate</option>
                  <option value="Brokers">Brokers</option>
                  <option value="Builders">Builders</option>
                  <option value="Sales">Sales</option>
                  <option value="Newsletter">Newsletter</option>
                  <option value="Third Party Agency">Third Party Agency</option>
                  <option value="Property Loans">Property Loans</option>
                  <option value="Secured Loans">Secured Loans</option>
                  <option value="Car Loans">Car Loans</option>
                  <option value="BANKS">BANKS</option>
                  <option value="2 Wheeler Loans">2 Wheeler Loans</option>
                  <option value="Business Loans">Business Loans</option>
                  <option value="Wedding Loans">Wedding Loans</option>
                  <option value="Other">Other</option>

                </select>
              </div>

              <div className="body-element">
                <label htmlFor="Description">Description</label>
                <textarea
                  cols="30"
                  name="description"
                  rows="10"
                  placeholder="Type Something"
                   {...register("description", { required: "This Field is empty" })}
                ></textarea>
              </div>
                 <div className="body-element">
                <label htmlFor="Description">Location</label>
                <input type="text" name="location" 
                 {...register("location", { required: "This Field is empty" })}
                placeholder="Location" />
              </div>


              <div className="body-element">
                <label htmlFor="Description">Contact Person Name</label>
                <input type="text" name="poc_name" 
                 {...register("poc_name", { required: "This Field is empty" })}
                placeholder="Enter Name" />
                  <input type="hidden" name="fi_id" 
                 {...register("id")}
                placeholder="Enter Name" />
              </div>
              <div className="body-element">
                <label htmlFor="Description">Contact Person Email</label>
                <input
                  type="email"
                  name="poc_email"
                   {...register("poc_email")}
                  placeholder="Enter Email"
                />
              </div>
 <div className="body-element">
                <label>Contact Number</label>
                {/* +00 extention input field*/}
                <div>
                  <select {...register("poc_phone_ext", { required: "+00" })}>
                    <option value="+91">+91</option>
                    <option value="+971">+971</option>
                  </select>
                  {errors.extention && (
                    <span className="error">{errors.extention.message}</span>
                  )}
                  <input
                    type="tel"
                    {...register("poc_phone", {
                      required: "Enter Your Number",
                    })}

                  />
                  {errors.contactNumber && (
                    <span className="error">
                      {errors.contactNumber.message}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="popup-footer">
            <button type="button" className="cancel-button" onClick={onClose}>
              Cancel
            </button>
            <button type="submit" className="save-button">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FiPopup;
