import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import "../../ClientSelect.css"; //main css
import "../WASimple.css"; //remaining css 1

import UploadIcon from "../../../../icons/UploadIcon.svg";
import DeleteIcon from "../../../../icons/trash-02.svg";

const VideoSimpleComponent = ({
  numVideoSimpleWA,
  URLVideoSimpleWA,
  fileUploadVideoSimpleWA,
  textTemplateVideoSimpleWA,
  register,
  errors,
  setValue,
  watch,
  componentClassName,
}) => {
  const [sendOption, setSendOption] = useState("link");

  const handleNumberChange = (event) => {
    setValue("numVideoSimpleWA", event.target.value);
  };

  const handleFileChange = (event) => {
    setValue("fileUploadVideoSimpleWA", event.target.files[0]);
  };

  const handleSendOptionChange = (event) => {
    setSendOption(event.target.value);
  };

  const handleLinkUrlChange = (event) => {
    setValue("URLVideoSimpleWA", event.target.value);
  };

  const handleTemplateTextChange = (event) => {
    const inputText = event.target.value;
    if (inputText.length <= maxCharacterCount) {
      setValue("textTemplateVideoSimpleWA", inputText);
    } else {
      setValue(
        "textTemplateVideoSimpleWA",
        inputText.slice(0, maxCharacterCount)
      );
    }
  };

  const maxCharacterCount = 1024;
  const characterCount = textTemplateVideoSimpleWA.length;
  const charactersLeft = maxCharacterCount - characterCount;

  return (
    <div className={`addClient-bottom component ${componentClassName}`}>
      <>
       
        <div className="addClient-field">
          <label>
            Select Video file to be sent to user
            <span className="star-req">*</span>
          </label>
          <div className="field-row">
            <label className="form-check-label">
              <input
                type="radio"
                className="form-check-input"
                name="sendOption3"
                value="link"
                defaultChecked
                checked={sendOption === "link"}
                onChange={(e) => setSendOption(e.target.value)}
              />
              Give a link
            </label>
            <label className="form-check-label">
              <input
                type="radio"
                className="form-check-input"
                name="sendOption3"
                value="upload"
                checked={sendOption === "upload"}
                onChange={(e) => setSendOption(e.target.value)}
              />
              Upload a file
            </label>
          </div>
        </div>

        {sendOption === "link" && (
          <div className="addClient-field">
            <label>
              Specify a link to an externally hosted video file (MP4 format
              only). Max size 5 MB
              <span className="star-req">*</span>
            </label>
            <input
              type="text"
              id="URLVideoSimpleWA"
              name="URLVideoSimpleWA"
              placeholder="https://www.mydomain.com/vid.mp4"
              value={URLVideoSimpleWA}
              {...register("URLVideoSimpleWA", { required: true })}
              onChange={handleLinkUrlChange}
            />
            <label className="lighter-note">
              Note: WhatsApp may compress large media files down to 5 MB during
              delivery.
            </label>
          </div>
        )}

        {sendOption === "upload" && (
          <div className="addClient-field">
            <label>
              Upload an video file (MP4 only) from your local computer. Max size
              5 MB
            </label>
            <div className="field-row">
              <input
                type="file"
                id="fileUploadVideoSimpleWA"
                name="fileUploadVideoSimpleWA"
                {...register("fileUploadVideoSimpleWA", { required: true })}
                accept=".mp4"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              <input
                type="text"
                placeholder=" File name shown here"
                value={
                  fileUploadVideoSimpleWA ? fileUploadVideoSimpleWA.name : ""
                }
                readOnly
              />
              <label
                htmlFor="fileUploadVideoSimpleWA"
                className="file-upload-label"
              >
                <img className="icon-width" src={UploadIcon} alt="^ Upload" />
              </label>
              {fileUploadVideoSimpleWA && (
                <img
                  className="icon-width delete-btn"
                  src={DeleteIcon}
                  alt="* Delete"
                  onClick={() => setValue("fileUploadVideoSimpleWA", null)}
                />
              )}
            </div>
            <label className="lighter-note">
              Note: WhatsApp may compress large media files down to 5 MB during
              delivery.
            </label>
          </div>
        )}

        <div className="addClient-field">
          <label>
            Select a template for caption text to be displayed with video
            <span className="star-req">*</span>
          </label>
          <textarea
            onChange={handleTemplateTextChange}
            id="textTemplateVideoSimpleWA"
            name="textTemplateVideoSimpleWA"
            value={textTemplateVideoSimpleWA}
            {...register("textTemplateVideoSimpleWA", { required: true })}
            cols="30"
            rows="10"
            placeholder="Click to change Template"
          ></textarea>
          <div className="template-status">
            <label>
              Characters used: {characterCount}, Left: {charactersLeft}
            </label>
            <Link>Use Template</Link>
          </div>
          {errors.textTemplateVideoSimpleWA && (
            <span className="error-message">Enter Template.</span>
          )}
        </div>
      </>
    </div>
  );
};

export default VideoSimpleComponent;
