import React, { useEffect, useState } from "react";

import axios from "axios";

import FiStatus from "../CommonComponents/Status";
import SearchAdd from "../FiManagement/SearchAdd";
import FiTable from "./FiTable";
import LoadingPopup from "../CommonComponents/LoadingPopup";

import Total from "../icons/transformation (3) 1.svg";
import Active from "../icons/interaction 1.svg";
import Inactive from "../icons/access-denied 1.svg";
import Planned from "../icons/internet-banking 1.svg";

const FiMainComp = () => {
  const [filist, setFis] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    setLoading(true);
    await axios.get("https://digitalxapi.cncitsolutions.in/api/filist").then(({ data }) => {
      setFis(data);
      //console.log(data);
    });
    setLoading(false);
  };

  const statusData = [
    {
      icon: Total,
      title: "Total FI's",
      value: filist.length.toString(),
      upValue: "10%",
    },
    {
      icon: Active,
      title: "Active FI's",
      value: filist.filter((fi_management) => fi_management.isActive === 1).length.toString(),
      upValue: "10%",
    },
    {
      icon: Inactive,
      title: "Inactive FI's",
      value: filist.filter((fi_management) => fi_management.isActive === 0).length.toString(),
      upValue: "10%",
    },
    {
      icon: Planned,
      title: "Total FI",
      value: filist.length.toString(),
    },
  ];
  return (
    <div className="mainContainer">
      <div className="mainWrapper">
        <div className="card-container">
          <FiStatus statusData={statusData} />
        </div>
        <div className="searchFilterContainer">
          <SearchAdd fetchProducts={fetchProducts}/>
        </div>
        <div className="searchResultsContainer FiSearchResults">
          {loading ? (
            <LoadingPopup show={true} />
          ) : (
            <FiTable data={filist} />
          )}
        </div>
      </div>
    </div>
  );
};

export default FiMainComp;
