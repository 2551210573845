import React, { useState, useRef, useEffect, useMemo, useCallback } from "react";
import FiPopup from "./FiPopup";
import Pagination from "../Pagination";
import { Link } from "react-router-dom";

import "../UserManagement/Tables/UserTable.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

const FiTable = ({ data, totalItems, currentPage, onPageChange }) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null); // State to store selected row data

  const openPopup = (row) => {
    setSelectedRow(row); // Set the selected row data
    setIsPopupVisible(true); // Open the popup
  };

  const closePopup = () => {
    setIsPopupVisible(false); // Close the popup
    setSelectedRow(null); // Reset the selected row data
  };

  return (
    <>
      <table className="user-management-table">
        <thead>
          <tr>
            <th>No.</th>
            <th>Name</th>
            <th>Location</th>
            <th>Category</th>
            <th>RM Name</th>
            <th>RM Contact</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              <td>{rowIndex + 1}</td>
              <td>
                <img src={`${window.$APIURL}filogo/${row.filogo}`} width="60px" alt="" />
                <div className="ml-10 pl-2">
                  <Link to={`/FiManagement/FiDetail/${row.id}`}>{row.finame}</Link>
                </div>
              </td>
              <td>{row.location}</td>
              <td>{row.poc_name}</td>
              <td>{row.poc_phone_ext} {row.poc_phone}</td>
              <td>{row.category}</td>
              <td>
                <button onClick={() => openPopup(row)}>Edit</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination totalItems={totalItems} currentPage={currentPage} onPageChange={onPageChange} />
      {isPopupVisible && (
        <FiPopup
          Title1="Edit User"
          onClose={closePopup}
          rowData={selectedRow} // Pass the selected row data to the popup
        />
      )}
    </>
  );
};

export default FiTable;
