import React, { useState } from "react";

// import { useAuth } from "./AuthContext";

// import Calendar from "./icons/Calendar.svg";
// import Downloads from "./icons/Downloads.svg";
// import Notifications from "./icons/Notifications.svg";
import UserImage from "./icons/User-image.svg";
import DownArrowSmall from "./icons/down-arrow-small.svg";
import axios from "axios";

import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";



import "./NavbarComp.css";

const NavbarComp = () => {
  // const { isAuthenticated, username, logout } = useAuth();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = () => {
    localStorage.clear();
  };


  const navigate = useNavigate();

  const [filist, setFis] = useState([]);

  const url = window.$APIURL;
  if (localStorage.getItem('tokss') == null || localStorage.getItem('tokss') == '') {
  navigate("/");
    return false
  }
  var token = 'Bearer '+ localStorage.getItem('tokss').replace(/^"(.+(?="$))"$/, '$1');;
  axios
  .post(url + "api/testtoken", '', {
    headers: {
      "Authorization": token
    },
  })
  .then(({ data }) => {
    if (data.status == true) {
      setFis( data.data.name);
window.$USERDATA = data.data; //global variable

} else {
  Swal.fire({
    icon: "error",
    text: data.message,
  });
  navigate("/");

}

        // window.location.reload(false);
})
  .catch(({ data }) => {
    if (data.status === 422) {
    } else {
      Swal.fire({
        text: data.message,
        icon: "error",
      });
    }
  });

  return (
    <nav className="horizontal-nav">
      <div className="welcome-text">Welcome, {filist}!!!</div>
      <ul className="nav-menu">
        {/* <li>
          <img className="logo-all" src={Calendar} alt="Calendar" />
        </li>
        <li>
          <img className="logo-all" src={Notifications} alt="Notifications" />
        </li> */}
        {/* <li>
          <img className="logo-all" src={Downloads} alt="downloads" />
        </li> */}

        {/* {isAuthenticated ? (
          <div>
            <p>Welcome, {username}!</p>
            <button onClick={logout}>Logout</button>
          </div>
        ) : (
          <button onClick={login}>Login</button>
        )} */}

        <li className="dropdown">
          <button onClick={handleDropdownToggle} className="dropdown-button">
            <img className="logo-all" src={UserImage} alt="UserImage" />
            <img
              className={`logo-all arrow-small ${isDropdownOpen ? "open" : ""}`}
              src={DownArrowSmall}
              alt="UserImage"
            />
          </button>
          {isDropdownOpen && (
            <div className="dropdown-content">
              <button onClick={handleLogout}>Logout</button>
            </div>
          )}
        </li>
      </ul>
    </nav>
  );
};

export default NavbarComp;
